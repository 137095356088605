import React, {lazy, useEffect} from 'react'
import './App.css';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom'
import {themeChange} from 'theme-change'
import checkAuth from './app/auth';
import initializeApp from './app/init';

// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const LayoutMember = lazy(() => import('./containers/member/Layout'))
const Login = lazy(() => import('./pages/Login'))
const Activation = lazy(() => import('./pages/Activation'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const ResetPassword = lazy(() => import('./pages/ResetPassword'))
const Register = lazy(() => import('./pages/Register'))
const Home = lazy(() => import('./pages/Home'))
const Event = lazy(() => import('./pages/Event'))
const DetailEvent = lazy(() => import('./pages/EventDetail'))
const RegistrationEvent = lazy(() => import('./pages/RegistrationEvent'))
const Member = lazy(() => import('./pages/Member'))
const Race = lazy(() => import('./pages/Race'))
const About = lazy(() => import('./pages/About'))

// Initializing different libraries
initializeApp()


// Check for login and initialize axios
const token = checkAuth()

function App() {

    const scope = localStorage.getItem('scope')

    useEffect(() => {
        themeChange(false)
    }, [])


    return (
        <Router>
            <Routes>
                <Route path="/home" element={<Home/>}/>
                <Route path="/event" element={<Event/>}/>
                <Route path="/event/:id" element={<DetailEvent/>}/>
                <Route path="/event/registration/:id" element={<RegistrationEvent/>}/>
                <Route path="/list-member" element={<Member/>}/>
                <Route path="/race" element={<Race/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/forgot-password" element={<ForgotPassword/>}/>
                <Route path="/forgot-password/:email/:token" element={<ResetPassword/>}/>
                <Route path="/register" element={<Register/>}/>
                <Route path="/activation-account/:token" element={<Activation/>}/>

                {/* Place new routes over this */}
                {scope === "Administrator" &&
                    <Route path="/app/*" element={<Layout/>}/>
                }

                {scope === "Member" &&
                    <Route path="/member/*" element={<LayoutMember/>}/>
                }

                <Route
                    path="*"
                    element={
                        <Navigate
                            to={
                                token
                                    ? scope === "Administrator"
                                        ? "/app/welcome"
                                        : scope === "Member"
                                            ? "/member"
                                            : "/home"
                                    : "/home"
                            }
                            replace
                        />
                    }
                />
            </Routes>
        </Router>
    )
}

export default App;
